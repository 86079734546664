<template>
  <div class="vg_wrapper">
    <el-card>
      <div style="display: flex">
        <div>
          <div class="vd_button_group vg_mb_8">
            <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
            <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete" icon="el-icon-delete">删除</el-button>
            <el-button type="success" size="small" class="vd_export" @click="jump('newWeeklyPOReport')">New Weekly PO Report</el-button>
            <el-button type="success" size="small" class="vd_export" @click="jump('barkOrders')">Bark Orders</el-button>
            <el-button type="success" size="small" class="vd_export" @click="jump('categorySalesStatistics')"> 品类销售统计 </el-button>
            <el-button type="warning" size="small" class="vd_export" @click="copyScon()">复制并编辑</el-button>
          </div>
          <div class="vd_button_group flexV vg_mb_8">
            <el-radio-group size="small" v-model="type" @input="val => getDataByType(val)">
              <el-radio-button :label="1">外销</el-radio-button>
              <el-radio-button :label="2">内贸</el-radio-button>
            </el-radio-group>
            <el-date-picker
              v-model="dateRange"
              class="vg_ml_8 vg_mr_8 topDateRangeSearch"
              end-placeholder="装运结束日期"
              range-separator="至"
              size="small"
              start-placeholder="装运开始日期"
              type="daterange"
              value-format="timestamp"
            />
            <el-date-picker
              v-model="dateRange2"
              class="vg_ml_8 vg_mr_8 topDateRangeSearch"
              end-placeholder="合同结束日期"
              range-separator="至"
              size="small"
              start-placeholder="合同开始日期"
              type="daterange"
              value-format="timestamp"
            />
            <el-button size="small" type="primary" @click="getSconsNow()">查询</el-button>
            <el-button size="small" type="info" @click="buttonRefresh()">刷新</el-button>
          </div>
        </div>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">当前页面货款金额累计: {{ accumulatedAmount.sumSconPrTotal }}</div>
            <span>合计: {{ accumulatedAmount.sumPrTotal }}</span>
          </div>
          <div style="display: flex">
            <div style="width: 250px">当前页面费用金额累计: {{ accumulatedAmount.sumSconFeTotal }}</div>
            <span>合计: {{ accumulatedAmount.sumFeTotal }}</span>
          </div>
          <div style="display: flex">
            <div style="width: 250px">当前页面合同金额累计: {{ accumulatedAmount.sumSconTotal }}</div>
            <span>合计: {{ accumulatedAmount.sumTotal }}</span>
          </div>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`scon_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getSconsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { sconAPI } from '@api/modules/scon';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import { useCustMixin, useStffMixin } from '@/assets/js/mixin/initMixin';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SalesManagement/SconManage/scon';
import { getDept, setTime } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';
import { cloneDeep } from 'lodash';

export default {
  name: 'SconList',
  mixins: [useStffMixin, useCustMixin],
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: tableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      accumulatedAmount: {},
      currentDeptId: this.$cookies.get('userInfo').dept_id,
      businessDept5: [],
      type: 1,
      sconForm: {
        scon_no: null, //外销合同编号
        scon_shipstat: null, //出运状态
        scon_cust_no: null, //客户订单号
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cust_name: null, //客户全称
        cust_bid: null, //最终客户id
        cust_babbr: null, //最总选择客户简称
        scon_cndate: new Date(), //合同日期
        scon_shdate: null, //装运日期
        cust_stff_id: null, //外销经办人
        cust_tradeway: 'General Trade', //贸易方式
        cust_shipway: null, //运输方式
        cust_paylaw: null, //价格条款
        cust_payway: null, //付款方式
        cust_creday: null, //客户账期
        scon_ppdate: null, //预计收款日期
        scon_sport: '上海', //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        scon_prtotal: null, //货款金额
        scon_fetotal: null, //费用金额
        scon_total: null, //合同金额
        cust_currency: null, //币种
        scon_usdrate: null, //美元汇率
        scon_rmbtotal: null, //本币金额 人民币
        scon_bulkname: '', //货物总称
        scon_insure: 'TO BE COVERED BY BUYER', //保险条款
        scon_pack: 'IN CARTONS', //包装要求
        scon_order_series: null, //订单系列
        scon_prod_list: [], //子表 商品明细
        scon_part_list: [], //子表 部件明细
        scon_catnip_list: [], //子表 猫草明细
        scon_mtrb_list: [], //子表 材料明细
        scon_fees_list: [], //子表 费用明细
        cust_dept_id: null, //外销所属部门 111111
        cptt_aid: null, //公司抬头id
        cptt_aname_en: null, //公司英文抬头
        cptt_bid: null, //收款抬头
        cust_shipmark: null, //唛头
        scon_remark: null, //备注
        clau_content: null, //条款内容
        inst_id: null, //货代id 机构
        inst_abbr: null, //机构名称
        inst_ename: null, //货代英文名称 机构
        cptt_bname_en: null, //收款抬头英文
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null //银行信息
      },
      dateRange: [],
      dateRange2: []
    };
  },
  async created() {
    this.getCustDport();
    this.getCustPayway();
    this.businessDept5 = await getDept('业务五部', 'ids');
    this.tableProperties.find(x => x.label === '录入部门').options = await getDept('', 'default', 'dept_name');
  },
  mounted() {
    let { scon_no, prod_no } = this.$route.query;
    if (scon_no) {
      this.$refs.multiTable.searchForm.scon_no = scon_no;
      this.$refs.multiTable.searchForm.scon_shipstat = null;
    }
    if (prod_no) {
      this.$refs.multiTable.searchForm = { ...this.$route.query, ...this.$refs.multiTable.searchForm };
    }
    this.$refs.multiTable.searchForm.scon_type = 1;
    this.getScons();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/scon_add' || from.path === '/scon_edit') {
        this.getScons();
      }
    }
  },
  methods: {
    getScons() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange, true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2, true);
      getNoCatch(sconAPI.getScons, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        for (const key in data) {
          if (key.includes('sum')) {
            this.accumulatedAmount[key] = data[key];
          }
        }
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    /**
     * 获取港口
     */
    getCustDport() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10018 }).then(({ data }) => {
        this.tableProperties[13].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
    },
    /**
     * 获取付款方式
     */
    getCustPayway() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10013 }).then(({ data }) => {
        this.tableProperties[12].options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });
    },
    /**
     * 查询方法
     */
    getSconsNow() {
      this.loadFlag = true;
      this.getScons();
    },
    /**
     * 刷新
     */
    buttonRefresh() {
      this.loadFlag = true;
      this.type = 1;
      this.$refs.multiTable.resetFields();
      this.$refs.multiTable.searchForm.scon_type = 1;
      this.dateRange = [];
      this.dateRange2 = [];
      this.getScons();
    },
    //复制并编辑
    copyScon() {
      if (this.multiSelection.length < 1) {
        return this.$message.error('请至少选择一条数据！');
      }
      if (this.multiSelection.length > 1) {
        return this.$message.error('只可选择一条数据进行复制！');
      }
      get(sconAPI.getSconById, { scon_id: this.multiSelection[0].scon_id })
        .then(res => {
          if (res.data.code === 0) {
            //主表数据
            this.sconForm = res.data.data.form;
            this.sconForm.scon_id = null;
            this.sconForm.scon_no = null;
            this.sconForm.scon_fees_list.forEach(item => {
              item.scon_fees_usd = this.helper.haveFour(item.scon_fees_usd);
            });
            this.sconForm.scon_cndate = this.sconForm.scon_cndate * 1000; //合同日期
            this.sconForm.scon_shdate = this.sconForm.scon_shdate * 1000; //装运日期
            this.sconForm.scon_usdrate = this.helper.haveFour(this.sconForm.scon_usdrate);
            this.sconForm.scon_ppdate = this.sconForm.scon_ppdate === 0 ? null : this.sconForm.scon_ppdate * 1000; //预计收款日期
            this.sconForm.scon_prtotal = this.helper.haveFour(this.sconForm.scon_prtotal);
            this.sconForm.scon_fetotal = this.helper.haveFour(this.sconForm.scon_fetotal);
            this.sconForm.scon_prtotal = this.helper.haveFour(this.sconForm.scon_prtotal);
            this.sconForm.scon_fetotal = this.helper.haveFour(this.sconForm.scon_fetotal);
            this.sconForm.status = 0; //状态为草拟
            this.sconForm.payment_status = 0; //锁定状态: 0 代表 未锁定  3代表锁定
            this.sconForm.fective_time = 0; //生效时间
            if (this.sconForm.scon_type === 0) {
              this.sconForm.scon_type = null;
            }
            //重置子表id
            this.sconForm.scon_prod_list.forEach(item => {
              item.scon_prod_id = null;
              item.prod_shipped = null; //需出运数量 注意：需出运数量 = 预计出运数量 - 已出运数量
              item.prod_shipstat = 0; //单个商品出运状态 默认为未出运状态，0=未出运，1=已出运，2=部分出运
            });
            this.sconForm.scon_part_list.forEach(item => {
              item.scon_part_id = null;
              item.scon_part_applied = null; //已申购数量 后面需根据成品采购合同的数量自动变化(包括删除成品采购合同时)，disabled
              item.part_shipstat = null; //部件出运状态 默认为未出运状态，0=未出运，1=已出运，2=部分出运
              item.qarma_status = 0; //是否已导入qarma   0未导入  1已导入
              item.create_status = 0; //采购合同状态
              item.scon_part_apply = item.scon_part_num_reality; //需申购默认等于实际数量
            });
            this.sconForm.scon_mtrb_list.forEach(item => {
              item.scon_mtrb_id = null;
              item.scon_mtrb_applied = null; //已申购数量
              item.modr_status = 0; //材料采购合同状态 0未生成,1部分生成,2已生成
              item.scon_mtrb_apply = item.scon_mtrb_num; //需申购默认等于实际数量
            });
            this.sconForm.scon_fees_list.forEach(item => {
              item.scon_fees_id = null;
            });
            this.sconForm.scon_catnip_list.forEach(item => {
              item.scon_catnip_id = null;
            });
            //跳转到add页面
            this.$router.push({
              name: 'SconAdd',
              query: { perm_id: this.$route.query.perm_id },
              params: { syncData: this.sconForm }
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.log(err));
    },
    /**
     * 分页查询
     */
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getScons();
    },
    /**
     * 多选获取公司抬头信息
     */
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    /**
     * 新增
     */
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/scon_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.scon_id);
      });
      post(sconAPI.deleteSconByIds, { scon_id_list: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.getScons();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getScons();
          }
        })
        .catch(err => {
          this.$message.error(err);
          this.getScons();
        });
    },
    /**
     * 双击row跳转到详情编辑
     */
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/scon_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.scon_id,
            scon_type: row.scon_type
          })
        )
      });
    },
    async getDataByType(type) {
      switch (type) {
        case 1:
          this.tableProperties[13].label = '目的港';
          this.tableProperties[13].prop = 'cust_dport';
          break;
        case 2:
          this.tableProperties[13].label = '目的地';
          this.tableProperties[13].prop = 'cust_bourm';
          this.tableProperties[13].itemType = 'input';
          break;
      }
      await this.$refs.multiTable.resetFields();
      await (this.$refs.multiTable.searchForm.scon_type = type);
      await this.getScons();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tooltip {
  width: 100%;
  padding: 0 2px;
  box-sizing: border-box;
  padding-right: 2px;
}

::v-deep .el-tooltip span {
  margin-left: 2px;
  margin-right: 4px;
}
</style>
